.splash-bg{
    background: no-repeat center center/cover;
    min-height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    animation: fadeIn 1s ease-in-out;
    flex-direction: column;
}

.splash-play-logo{
    width: 200px;
    height: 90px;
    object-fit: contain;
    cursor: pointer;
}

.splash-screen-logo{
    width: 40%;
    object-fit: contain;
}

.splash-play-logo{
    width: 18%;
}