.game-bg {
  background: no-repeat center center/cover;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.game-header {
  width: 96%;
  height: 74px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
}

.left-header,
.right-header {
  gap: 15px;
  position: relative;
}

.user-profile {
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.wallet-add,
.user-coin-value {
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 700;
}

.user-money {
  justify-content: flex-start;
  /* width: 100%; */
  cursor: pointer;
}

.coin-icon {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.user-coin-value {
  margin: 0px 8px;
}

.icon {
  cursor: pointer;
  width: 53px;
  height: 53px;
}

.game-container-wrapper {
  flex-direction: column;
  width: 100%;
}

.game-container {
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
  padding-bottom: 1rem;
  margin-top: 1rem;
}

.game-heading {
  width: 382px;
  height: 61px;
  object-fit: contain;
}

.win-lose-balance-box::before {
  content: "";
  position: absolute;
  border-radius: 25px;
  background: #2f6ec9;
  mix-blend-mode: multiply;
  inset: 5px;
}

.win-lose-balance-box {
  justify-content: space-around;
  width: 650px;
  height: 50px;
  border-radius: 25px;
  background: linear-gradient(323deg, #0063c4 2.15%, #26d4ff 97.85%);
  position: relative;
  box-shadow: 0px 0px 15px rgba(48, 132, 254, 0.75);
}

.wlb-txt {
  color: var(--primary-color);
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
}

.coin-flip-box {
  width: 651px;
  height: 250px;
  position: relative;
  background: linear-gradient(0deg,
      #6687b0 0%,
      #577396 28%,
      #42566f 75%,
      #3a4b61 100%);
  border-radius: 15px;
  overflow: hidden;
}

.inside-1-coin-flip-box {
  background: radial-gradient(364.42% 132.06% at -8.87% -30.56%,
      #7ba0c6 0%,
      #080819 100%);
  background-blend-mode: screen;
  mix-blend-mode: screen;
  border-radius: 15px;
  position: absolute;
  left: 5px;
  top: 8px;
  right: 5px;
  bottom: 0;
}

.inside-2-coin-flip-box {
  background: radial-gradient(364.42% 132.06% at -8.7% -30.56%,
      #7ba0c6 0%,
      #080819 100%);
  background-blend-mode: screen;
  mix-blend-mode: screen;
  position: absolute;
  inset: 0;
  border-radius: 15px;
}

.inside-3-coin-flip-box {
  background: radial-gradient(114.91% 42.95% at 104.88% 146.36%,
      #7ba0c6 0%,
      #7497bc 4%,
      #4e6482 29%,
      #2f3c55 52%,
      #1a1f34 72%,
      #0c0e20 89%,
      #080819 100%);
  background-blend-mode: screen;
  mix-blend-mode: screen;
  inset: 0;
  border-radius: 15px;
  position: absolute;
}

.border-left {
  width: 2.62px;
  height: 87%;
  flex-shrink: 0;
  background: linear-gradient(0deg, #6588eb 0%, #38659c 98%);
  position: absolute;
  left: 18px;
  border-radius: 30px;
}

.border-top {
  background: linear-gradient(0deg,
      #7285a8 0%,
      #8093b3 19%,
      #a7b9d1 54%,
      #e1f2ff 100%);
  background-blend-mode: screen;
  mix-blend-mode: screen;
  position: absolute;
  top: 15px;
  left: 20px;
  width: 94%;
  height: 2.62px;
  flex-shrink: 0;
}

.border-right {
  width: 2.62px;
  height: 87%;
  flex-shrink: 0;
  background: linear-gradient(0deg, #6588eb, #38659c);
  position: absolute;
  right: 18px;
  border-radius: 30px;
}

.inside-background {
  background: radial-gradient(304.85% 104.9% at 1.23% -1.16%,
      #0cf 0%,
      #1966e3 99%);
  position: absolute;
  top: 17px;
  right: 21px;
  left: 20px;
  bottom: 17px;
}

.left-glass,
.middle-glass,
.right-glass {
  position: absolute;
}

.left-glass,
.middle-glass,
.right-glass {
  height: 100%;
  object-fit: contain;
  z-index: -1;
  left: 1%;
  top: -2%;
  mix-blend-mode: screen;
}

.middle-glass {
  top: initial;
  left: initial;
}

.right-glass {
  right: -2%;
  top: initial;
  left: initial;
}

.streak-multiplier-box {
  width: 92%;
  height: 55px;
  justify-content: space-between;
  border-radius: 40px;
  border: 3px solid #84008c;
  background: #cd21d8;
  z-index: 10;
}

.streak-box,
.multiplier-box {
  flex-direction: column;
}

.streak-box {
  margin-left: 20px;
}

.multiplier-box {
  margin-right: 20px;
}

.streak-multiplier-txt {
  color: var(--primary-color);
  text-align: center;
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
}

.multiplier-value {
  text-transform: none;
}

.coin {
  position: absolute;
  width: 175px;
  height: 175px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-style: preserve-3d;
  z-index: 10;
}

.tail,
.head {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  backface-visibility: hidden;
  background-size: contain;
  position: absolute;
}

.head {
  background-image: url("../assets/head.png");
}

.animate-head {
  animation: flipHead 3s ease-in-out forwards;
}

.tail {
  background-image: url("../assets/tail.png");
  transform: rotateY(-180deg);
}

.animate-tail {
  animation: flipTail 3s ease-in-out forwards;
}

/* second condition */
.head-2 {
  transform: rotateY(-180deg);
}

.animate-head2 {
  animation: flipHead2 3s ease-in-out forwards;
}

.tail-2 {
  transform: rotateY(0deg);
}

.animate-tail2 {
  animation: flipTail2 3s ease-in-out forwards;
}

/* ************************* */

.result-box-bg {
  /* background: url('../assets/result-bg.png') no-repeat center center/cover; */
  width: 650px;
  height: 87px;
  position: relative;
  border-radius: 15px;
  background: linear-gradient(323deg, #0063c4 2.15%, #26d4ff 97.85%);
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
}

.result-cover-box {
  position: absolute;
  inset: 5px;
  border-radius: 10px;
  background: #0348ac;
  overflow: hidden;
  padding: 0 15px;
}

.wrapper-result-container {
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  overflow: hidden;
}

.result-container {
  justify-content: flex-end;
  gap: 11.3px;
  flex-shrink: 0;
  width: 5840px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.result-coin {
  width: 64px;
  height: 64px;
  object-fit: contain;
}

.bid-box-bg {
  /* background: url('../assets/bid-bg.png') no-repeat center center/cover; */
  width: 639px;
  height: 142px;
}

.bid-container {
  width: 92%;
  height: 90%;
  justify-content: space-between;
  position: relative;
  z-index: 10;
}

.bid-box-inside-color {
  background: radial-gradient(360.25% 141.13% at 1.23% -1.16%,
      #00267b 0%,
      #0133a2 100%);
  top: 12px;
  right: 21px;
  left: 15px;
  bottom: 10px;
}

.bid-box-inside-1 {
  left: 3px;
  top: 5px;
  right: 3px;
}

.bid-box-border-left,
.bid-box-border-right {
  height: 86%;
}

.bid-box-border-left {
  left: 2%;
}

.bid-box-border-top {
  top: 7%;
  left: 2%;
}

.bid-box-border-top {
  width: 95%;
}

.min-max-box,
.half-twoX-box {
  flex-direction: column;
  justify-content: space-between;
  height: 85%;
}

.bid-btn {
  cursor: pointer;
  width: 79px;
  height: 33px;
  object-fit: contain;
}

.bid-increase-decrease-box {
  justify-content: space-between;
  width: 47%;
}

.position-set {
  position: relative;
  top: 6px;
  width: 50px;
  height: 50px;
}

.bid-value {
  color: var(--primary-color);
  font-size: 42.44px;
  font-weight: 700;
}

.bet-btns-box {
  width: 500px;
  justify-content: space-between;
}

.bet-btn {
  width: 194px;
  height: 62px;
  cursor: pointer;
  transition: scale 0.5s ease-in-out;
  background-color: transparent;
  position: relative;
}

.light-theme-buttons {
  inset: 0;
  position: absolute;
  padding: 0 10px;
  gap: 5px;
}

.light-bet-btn {
  color: var(--primary-color);
  text-shadow: 0px 1px 4px rgba(255, 255, 255, 0.25);
  font-size: 22px;
  font-weight: 800;
  text-transform: uppercase;
}

.bet-coin-image {
  width: 40px;
  height: 40px;
  object-fit: contain;
  /* position: absolute;
    left: 9px;
    bottom: 13px; */
}

.footer-bg::before {
  content: "";
  position: absolute;
  inset: 5px;
  border-radius: 25px;
  background: #2f6ec9;
  mix-blend-mode: multiply;
}

.footer-bg {
  width: 650px;
  height: 50px;
  /* margin-top: 15px; */
  border-radius: 25px;
  background: linear-gradient(323deg, #0063c4 2.15%, #26d4ff 97.85%);
  position: relative;
  box-shadow: 0px 0px 15px rgba(48, 132, 254, 0.75);
  flex-shrink: 0;
}

.footer-txt-box {
  justify-content: space-between;
  width: 90%;
  position: relative;
}

.change-game-mode {
  width: 28%;
  justify-content: space-between;
}

.footer-txt {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
}

.toggle-btn {
  width: 60px;
  height: 20px;
  border-radius: 50px;
  background-color: #fff;
  position: relative;
  cursor: pointer;
}

.theme-button {
  justify-content: space-between;
  padding: 0 3px;
}

.btn-circle {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  top: -6px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.theme-circle {
  border-radius: 33.558px;
  border: 1.5px solid #050e62;
  background: linear-gradient(0deg, #212af6 0%, #0517ba 0.01%, #677fff 100%);
}

.theme-inside-icon {
  width: 15px;
  height: 15px;
  object-fit: contain;
}

.horizontal-line {
  width: 70%;
  height: 15%;
  background-color: var(--primary-color);
  border-radius: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.second {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.right-arrow {
  height: 15px;
  position: relative;
  top: 2px;
}

.result-message {
  width: 452px;
  height: 140px;
  object-fit: contain;
  position: fixed;
  z-index: 20;
  top: 50%;
  left: 50%;
  /* transform: translate(-50%, -1000%); */
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.result-visible {
  opacity: 1;
}

#lose-message {
  filter: drop-shadow(1px 1px 0px #000000);
}

.add-box {
  width: 650px;
  height: 165px;
  border-radius: 25px;
  background: linear-gradient(180deg, #fff 0%, #3532fb 100%);
  padding: .5rem;
  overflow: hidden;
}

.add-banner {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.add-token-btn {
  background-color: transparent;
  width: 7.5rem;
  height: 3.25rem;
  border-radius: 1.75rem;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.add-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.add-token-icon-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: Montserrat;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 800;
  background: linear-gradient(0deg, #CE8111 0%, #FBA90C 99.92%);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-variant: all-small-caps;
  line-height: 1.1rem;
  mix-blend-mode: multiply;
}