.internet-connectivity {
    flex-direction: column;
    background-color: var(--background-visible-color);
    height: 450px;
    width: 572px;
    border-radius: 15px;
    border: 5px solid #26D4FF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    justify-content: flex-start;
}

.network-heading {
    color: var(--heading-color);
    text-shadow: 0px 0px 2px rgba(246, 238, 40, 0.75);
    font-size: 50px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 15px;
}

.network-message-wrapper {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
}

.net-icon {
    width: 200px;
    height: 200px;
    object-fit: contain;
}

.internet-status-message {
    color: var(--primary-color);
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 25px;
    text-align: center;
}