.wallet-container{
    padding-bottom: 20px;
}

.wallet-details {
    width: 90%;
    justify-content: space-between;
    align-items: flex-end;
}

.wallet-detail-left-side{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.wallet-subheading-box{
    width: 90%;
    justify-content: flex-start;
}

.account-heading,
.wallet-address-id,
.wallet-balance-txt,
.wallet-balance-value {
    color: var(--primary-color);
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
}

.wallet-address-id, .wallet-balance-txt {
    text-align: left;
    font-size: 18px;
    cursor: pointer;
}

.wallet-balance-value{
    font-size: 35px;
}

.wallet-middle-wrapper{
    width: 37.5%;
    justify-content: space-between;
    margin-left: 56px;
}

.wallet-transaction-bg{
    width: 100%;
}

.wallet-subheading{
    font-size: 18px;
}

.amt-txt{
    margin-left: 85px;
}

.wallet-subheading-data-box{
    width: 75%;
    justify-content: space-between;
}

.wallet-transaction-history-container{
    height: 350px;
    width: 100%;
    position: relative;
}

.wallet-date-wrapper{
    width: 45%;
    justify-content: space-between;
}

.wallet-subheading-data{
    font-weight: 600;
    font-size: 18px;
}

/* Wallet Transaction Screen */
.wallet-subheading-box{
    justify-content: space-between;
}

.wallet-middle-wrapper{
    width: 32%;
    margin-left: 0;
}

.amt-txt{
    margin-left: 0;
}

.wallet-transaction-bg{
    width: 100%;
}

.wallet-subheading-data-box{
    width: 90%;
}

.hash-id{
    width: 197.08px;
    text-align: center;
}

.wallet-date-wrapper{
    width: 35%;
}

.wallet-amount-value{
    width: 86.03px;
    text-align: center;
}

.no-trasaction-found{
    width: 100%;
    text-align: center;
}