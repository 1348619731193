.setting-container {
    flex-direction: column;
    height: calc(100vh + 20px);
    padding: 60px 40px;
    padding-bottom: 0 !important;
    position: fixed;
    z-index: 10;
    right: 0;
    top: -20px;
    justify-content: flex-start;
    background: rgba(56, 56, 56, 0.27);
    box-shadow: -4px 0px 4px 0px rgba(154, 165, 187, 0.25);
    transition: all .5s ease-in-out;
    transform: translateX(550px);
    overflow: auto;
}

.visible {
    transform: translateX(0);
}

.setting-game-heading {
    width: 274px;
    height: 55px;
    object-fit: contain;
    margin-top: 50px;
}

.setting-horizontal-line {
    width: 250px;
    border: 1px solid #26D4FF;
    margin-top: 15px;
}

.sound-container {
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.sound-box {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.sound-heading {
    color: var(--primary-color);
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
}

.sound-level-bar {
    width: 100%;
    border-radius: 15px;
    border: 3px solid #26D4FF;
    height: 15px;
    position: relative;
}

.sound-level-bg {
    position: absolute;
    border-radius: 15px;
    height: 100%;
    background-color: #4692FF;
}

.sound-bar {
    width: 100%;
    height: 100%;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 15px;
    background-color: transparent;
    position: absolute;
    z-index: 10;
}

.sound-bar::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 15px;
    background: #0D1B4E;
    box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.25) inset;
    cursor: pointer;
}


.setting-action-container {
    flex-direction: column;
    gap: 15px;
    margin-top: 50px;
    width: 100%;
    align-items: flex-start;
}

.action-box {
    gap: 10px;
    justify-content: space-around;
    cursor: pointer;
}

.action-box-img {
    width: 52px;
    height: 52px;
    object-fit: contain;
}

.action-lable-name {
    color: var(--primary-color);
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
}


.setting-close-btn {
    position: absolute;
    left: 35px;
    width: 20px;
    height: 15px;
    object-fit: contain;
    cursor: pointer;
}

.copyright-container{
    height: 100%;
    align-items: flex-end;
}

.copyright-subheading {
    /* color: #808080; */
    color: #d3c9c9;
    font-size: 14.013px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 30px;
}