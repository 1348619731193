.drop-down-list {
    background-color: var(--dropDown-bg);
    position: absolute;
    width: 130px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 5px;
    z-index: 10;
    max-height: 0;
    overflow: hidden;
    transition: max-height .8s ease-in-out;
    top: 60px;
    right: 0;
}

.drop-down-item{
    gap: 5px;
    padding: 5px;
    cursor: pointer;
    width: 100%;
    justify-content: flex-start;
}

.drop-down-item:hover{
    background-color: var(--wallet-list-hover);
}

.wallet-icon-name{
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.user{
    filter: invert(1);
}

.wallet-name{
    color: var(--wallet-name);
    font-size: 15px;
    font-weight: 600;
}

.separate-list-item{
    width: 100%;
    height: 1px;
    background-color: #413f3f;

}

.max-height{
    max-height: 300px;
}