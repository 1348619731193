/* ********************************************** */
/* ********************************************* */
/* *******width 1220px and height 900px ******* */
/* ******************************************* */
/* ******************************************* */

@media (max-width : 1770px) and (min-width : 528px) and (max-height : 900px) {
    .game-bg {
        padding: 10px 0;
        min-height: 100vh;
    }

    .heightWidth{
        height: calc(100vh - 94px);
    }

    .game-container {
        gap: 8px;
    }

    .win-lose-balance-box {
        height: 45px;
    }

    .coin-flip-box {
        height: 220px;
    }

    .bid-box-bg {
        height: 142px;
    }

    .footer-bg {
        margin-top: 8px;
    }
}

/* ********************************************** */
/* ********************************************* */
/* *******width 1220px and height 790px ******* */
/* ******************************************* */
/* ******************************************* */

@media (max-width : 1770px) and (min-width : 528px) and (max-height : 790px) {

    /* splash screen */
    .splash-screen-logo {
        height: 65%;
        object-fit: contain;
    }

    .splash-play-logo{
        height: 13%;
    }

    .result-message{
        height: 120px;
    }

    .icon {
        width: 45px;
        height: 45px;
    }

    .game-heading {
        height: 55px;
    }

    .win-lose-balance-box {
        height: 40px;
    }

    .wlb-txt {
        font-size: 15px;
    }

    .coin {
        width: 150px;
        height: 150px;
    }

    .coin-flip-box {
        height: 200px;
    }

    .bid-box-bg {
        height: 120px;
    }

    .bid-btn {
        width: 70px;
        height: 28px;
    }

    .bid-container {
        height: 83%;
    }

    .position-set {
        width: 45px;
        height: 45px;
    }

    .streak-multiplier-box {
        height: 50px;
    }

    .streak-multiplier-txt {
        font-size: 15px;
    }

    .bet-btn {
        width: 164px;
        height: 50px;
    }

    .light-bet-btn{
        font-size: 19px;
    }

    .bet-coin-image{
        width: 35px;
        height: 35px;
    }

    .footer-bg {
        height: 43px;
    }

   /* Rule Screen */
    .game-rule-box, .game-terms-box, .game-hotkey-box {
        overflow: auto;
    }
}

/* ********************************************** */
/* ********************************************* */
/* *******width 1220px and height 700px ******* */
/* ******************************************* */
/* ******************************************* */

@media (max-width : 1770px) and (min-width : 400px) and (max-height : 700px){
    .game-container-wrapper{
        justify-content: flex-start;
    }
    .result-message{
        height: 103px;
    }
    .icon{
        display: none;
    }

    .game-header{
        height: initial;
    }

    .ham-burger{
        display: block;
    }

    .game-container{
        gap: 5px;
    }

    .game-heading{
        height: 45px;
    }

    .win-lose-balance-box::before{
        inset: 2px;
    }

    .win-lose-balance-box {
    height: 35px;
    }

    .coin {
        width: 135px;
        height: 135px;
    }

    .coin-flip-box{
        height: 180px;
    }

    .result-box-bg{
        height: 80px;
    }

    .border-right, .border-left{
        height: 83%;
    }

    .bid-box-bg{
        height: 120px;
    }

    /* rule screen */
    /* .heightWidth{
        height: 100%;
    } */

    /* Network Error */
    .internet-connectivity{
        height: initial;
        width: 80%;
    }

    .internet-status-message{
        margin-bottom: 15px;
    }

    .heightWidth{
        height: initial;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
}


/* ********************************************** */
/* ********************************************* */
/* *******width 375px and height 690px ******* */
/* ******************************************* */
/* ******************************************* */

@media (max-width : 375px) and (max-height : 630px){
    .game-bg{
        height: initial;
    }

    .game-header{
        margin-top: 20px;
    }

    .game-container-wrapper{
        margin-bottom: 10px;
        margin-top: 10px;
    }
}