.dashboard-container {
    background: var(--background-visible-color);
    border-radius: 15px;
    border: 5px solid var(--border-color);
    width: 878px;
    padding: 20px 15px 60px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 100;
}

.dashboard-heading {
    color: var(--heading-color);
    text-shadow: 0px 0px 2px rgba(246, 238, 40, 0.75);
    font-size: 50px;
    font-weight: 700;
    text-transform: uppercase;
}

.dashboard-stats-container {
    justify-content: space-between;
    width: 90%;
    margin-top: 50px;
}

.dashboard-left-side {
    flex-direction: column;
    align-items: flex-start;
    gap: 60px;
}

.dashboard-stat-color-box {
    width: 50px;
    height: 40px;
    border-radius: 5px;
    border: 3px solid var(--primary-color);
}

.dashboard-stat-value, .dashboard-stat-name {
    color: var(--primary-color);
    text-shadow: 0px 0px 3.244374990463257px rgba(180, 40, 246, 0.75);
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
}

.dashboard-stat-value{
    margin-left: 15px;
}

.dashboard-stat-name {
    font-weight: 400;
    text-transform: uppercase;
}

/* .dashboard-right-side{

} */

.pie-chart{
    position: absolute;
    right: 50%;
    left: 50%;
}