.token-heading {
    color: var(--heading-color);
    text-shadow: 0px 0px 2px rgba(246, 238, 40, 0.75);
    font-size: 50px;
    font-weight: 700;
    text-transform: uppercase;
}

.token-count {
    color: var(--primary-color);
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 600;
    font-variant: all-small-caps;
    margin: 50px 0;
}

.token-counter {
    width: 80%;
    justify-content: space-between;
}

.minus-token,
.plus-token {
    width: 50px;
    height: 50px;
    object-fit: contain;
    position: relative;
    top: 3px;
    cursor: pointer;
}

.token-amount-box-border {
    width: 294px;
    height: 60px;
    padding: 5px;
    position: relative;
    border-radius: 5px;
    background: linear-gradient(323deg, #3892EA 2.15%, #26D4FF 97.85%);
    flex-shrink: 0;
}

.token-amount-bg {
    background: #2B2F35;
    mix-blend-mode: multiply;
    border-radius: 5px;
    position: absolute;
    inset: 5px;
}

.token-value {
    color: var(--primary-color);
    font-family: Montserrat;
    font-size: 35px;
    font-weight: 700;
    font-variant: all-small-caps;
    position: relative;
    text-align: center;
    width: 97%;
    height: 62%;
    padding-bottom: 6px;
    background: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number]{
    appearance: none;
    -moz-appearance: textfield;
}

.token-convert-value {
    color: var(--primary-color);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
    text-transform: capitalize;
}

.transaction-btn {
    margin-top: 60px;
    background: transparent;
}

.token-btn {
    width: 200px;
    height: 50px;
    object-fit: contain;
    cursor: pointer;
}

.transaction-box {
    width: 483px;
    height: 273px;
    position: absolute;
    border-radius: 15px;
    border: 5px solid var(--border-color);
    flex-direction: column;
}

.transaction-txt {
    color: var(--primary-color);
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;
    width: 95%;
}

.pending{
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.loader {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 4px solid #26D4FF;
    border-bottom-color: transparent !important;
    animation: loading 1s ease-in-out infinite;
}

.amount-loader {
    width: 30px;
    height: 30px;
    border-width: 3px;
    margin-left: 10px;
}