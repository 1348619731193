.stats-screen-container {
    background: var(--background-visible-color);
    border-radius: 15px;
    border: 5px solid var(--border-color);
    width: 878px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    z-index: 100;
}

.stats-heading {
    color: var(--heading-color);
    text-shadow: 0px 0px 2px rgba(246, 238, 40, 0.75);
    font-size: 50px;
    font-weight: 700;
    text-transform: uppercase;
}

.stats-subheading-bg::before,
.leaderboard-subheading-bg::before,
.wallet-subheading-bg::before {
    content: '';
    position: absolute;
    inset: 5px;
    background: #2F6EC9;
    mix-blend-mode: multiply;
    border-radius: 34px;
    z-index: -1;
}

.stats-subheading-bg,
.leaderboard-subheading-bg,
.wallet-subheading-bg {
    background: linear-gradient(323deg, #0063C4 2.15%, #26D4FF 97.85%);
    width: 800px;
    height: 60px;
    border-radius: 34px;
    position: relative;
    z-index: 1;
}

.stats-subheading-box,
.leaderboard-subheading-box,
.leaderboard-subheading-data-box,
.wallet-subheading-box {
    width: 95%;
    justify-content: space-around;
}

.stats-subheading,
.leaderboard-subheading,
.leaderboard-subheading-data,
.wallet-subheading-data,
.wallet-subheading {
    color: var(--primary-color);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 20px;
}

.stats-vertical-line,
.leaderboard-vertical-line {
    width: 3.2px;
    height: 33.5px;
    border-radius: 10px;
    background: linear-gradient(323deg, #0063C4 2.15%, #26D4FF 97.85%);
    opacity: 0.8;
}

.stats-box {
    border-radius: 22.024px;
    border: 2.5px solid #139DE2;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, #0550BE 0%, #0238A7 100%);
    box-shadow: 0px 0px 10.57143px 0px #149EE2;
    width: 718px;
    height: 370px;
    margin: 30px 0;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
}

.stats-heading-box {
    justify-content: space-between;
    width: 90%;
    margin-top: 30px;
}

.waged-heading {
    color: var(--heading-color);
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
}

.stats-headings {
    justify-content: space-between;
    width: 60%;
}

.stats-data-heading {
    width: 25%;
    justify-content: space-between;
}

.win-data-heading{
    justify-content: space-around;
}

.heading-color {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #CD21D8;
}

.heading-txt {
    color: var(--primary-color);
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
}

.stats-chart {
    position: absolute;
    top: 10px;
    width: 100%;
    height: 100%;
}

.no-stats-found, .no-trasaction-found {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: var(--heading-color);
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
}