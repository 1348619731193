.game-rule-box, .game-terms-box, .game-hotkey-box {
    background: var(--background-visible-color);
    border-radius: 15px;
    border: 5px solid var(--border-color);
    width: 878px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    z-index: 100;
}

.description-heading, .rule-heading, .terms-heading,.hotkey-heading {
    color: var(--heading-color);
    text-shadow: 0px 0px 2px rgba(246, 238, 40, 0.75);
    font-size: 34.384px;
    font-weight: 700;
    text-transform: uppercase;
}

.rule-heading{
    margin-top: 30px;
}

.description-txt, .rule-txt {
    color: var(--primary-color);
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 25px;
}

.close{
    color: var(--primary-color);
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--primary-bg);
    cursor: pointer;
}

.cross::before, .cross::after{
    content: '';
    position: absolute;
    width: 90%;
    height: 4px;
    background-color: var(--primary-color);
    border-radius: 30px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}

.cross{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
    background-color: transparent;
}

.cross::after{
    transform: translate(-50%, -50%) rotate(-45deg);
}