.game-terms-box{
    height: 500px;
    overflow: auto;
}

.welcome-to-coin-flip, .full-terms-condition {
    color: var(--primary-color);
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
}

.full-terms-condition{
    font-weight: 400;
    line-height: 25px;
    text-transform: capitalize;
}

.increase-font-weight{
    font-weight: 700;
}