.leaderboard-container, .wallet-container {
    background: var(--background-visible-color);
    border-radius: 15px;
    border: 5px solid var(--border-color);
    width: 878px;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    z-index: 100;
}

.leaderboard-heading, .wallet-heading {
    color: var(--heading-color);
    text-shadow: 0px 0px 2px rgba(246, 238, 40, 0.75);
    font-size: 50px;
    font-weight: 700;
    text-transform: uppercase;
}

.leaderboard-users-container, .wallet-transaction-history-container {
    flex-direction: column;
    gap: 15px;
    height: 400px;
    overflow: auto;
    justify-content: flex-start;
    padding: 15px 0;
}

.leadboard-user-bg-black::before,
.wallet-user-bg-black::before
 {
    content: '';
    position: absolute;
    inset: 5px;
    border-radius: 35px;
    background: #2B2F35;
    mix-blend-mode: multiply;
}

.leadboard-user-bg-black, 
.wallet-user-bg-black {
    width: 800px;
    height: 50px;
    border-radius: 35px;
    background: linear-gradient(323deg, #3892EA 2.15%, #26D4FF 97.85%);
    position: relative;
    box-shadow: 0px 0px 12px #149EE2;
    flex-shrink: 0;
}

.leaderboard-user-bg, .wallet-transaction-bg{
    width: 97%;
    position: relative;
}

.leaderboard-subheading-box, .leaderboard-subheading-data-box{
    justify-content: space-between;
    width: 80%;
}

.leaderboard-subheading-data-box{
    width : 90%;
}

.leaderboard-subheading-data{
    font-variant: normal;
    text-transform: uppercase;
    font-size: 20px;
}

.leaderboard-subheading-data{
    width: 145px;
    text-align: center;
}

.add-wrapper{
    justify-content: space-between;
    width: 45%;
}