.hotkey-keyboard {
    width: 87%;
    height: 250px;
    object-fit: contain;
    margin: 10px 0;
}

.keys-container {
    width: 95%;
    flex-wrap: wrap;
    gap: 20px 25px;
    justify-content: center;
}

.key-box {
    justify-content: space-between;
    gap: 10px;
}

.key-cap {
    width: 48px;
    height: 48px;
    border: 2px solid var(--key-border);
    border-radius: 10px;
    color: var(--primary-color);
    font-family: Arial;
    font-size: 30px;
    font-weight: 400;
    text-transform: uppercase;
}

.key-value {
    color: var(--primary-color);
    font-family: Arial;
    font-size: 20px;
    font-weight: 400;
}

.hotkey-toggle-box {
    width: 87%;
    justify-content: flex-start;
    margin: 15px 0;
    gap: 15px;
}

.hokey-sub-heading {
    color: var(--primary-color);
    font-size: 30px;
    font-weight: 700;
}

.hotkey-toggle{
    top: 3px;
}
