.addToken-container, .redeemToken-container {
    background: var(--background-visible-color);
    border-radius: 15px;
    border: 5px solid var(--border-color);
    width: 572px;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 100;
}