@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  border: none;
  outline: none;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
}

body {
  background-color: #000;
}

.dark-theme {
  --primary-color: #fff;
  --heading-color: #EFCA07;
  --border-color: #26D4FF;
  --background-visible-color: rgba(47, 110, 201, 0.25);
  --key-border: #0057FF;
  --dropDown-bg: #333;
  --wallet-name: #fff;
  --wallet-list-hover: #292727;
  --internet-bg: #000;
  --internet-message: #fff;
}

.light-theme {
  --primary-color: #fff;
  --heading-color: #EFCA07;
  --border-color: #26D4FF;
  --background-visible-color: #2F6EC9;
  --dropDown-bg: #ffffff;
  --wallet-name: #111;
  --wallet-list-hover: #c3bbbb;
  --key-border: #42ACE8;
  --internet-bg: #fff;
  --internet-message: #000;

}

.Toastify__progress-bar-theme--dark {
  background: #EFCA07 !important;
}

.Toastify__progress-bar-theme--light {
  background: #EFCA07 !important;
}

.blur {
  filter: blur(9px);
}

.blur2 {
  filter: blur(15px);
}

.blur3 {
  filter: blur(80px);
}


.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.heightWidth {
  width: 100%;
  height: calc(100vh - 114px);
}

.disable-link{
  pointer-events: none;
  user-select: none;
}

.under-maintance {
   background-image: url('../assets/main-bg.png');
   background-size: contain;
   color: #000000;
   height: 100dvh;
   display: grid;
   place-items: center;
   animation: fadeIn 1s linear;
}

.under-maintance .card {
    min-width: 30%;
    width: 25rem;
    height: auto;
    border: 3px solid var(--border-color);
    background-color: var(--background-visible-color);
    border-radius: 10px;
    background-size: contain;
    text-align: center;
    line-height: 1.4;
    text-transform: uppercase;
    padding-top: 2rem;
    transform: translateY(50px);
}

.under-maintance .card > .card-heading {
    color: var(--heading-color);
}

.card > .card-text {
   font-size: 1rem;
   color: var(--primary-color);
   width: 80%;
   margin: 2rem auto;
}

@keyframes flipHead {
  0% {
    transform: translate(-50%, -50%) rotateY(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotateY(1800deg);
  }
}

@keyframes flipTail {
  0% {
    transform: translate(-50%, -50%) rotateY(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotateY(1620deg);
  }
}

@keyframes flipHead2 {
  0% {
    transform: translate(-50%, -50%) rotateY(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotateY(1620deg);
  }
}

@keyframes flipTail2 {
  0% {
    transform: translate(-50%, -50%) rotateY(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotateY(1800deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}