/* ************************** */
/* ************************** */
/* *******width 990px******* */
/* ************************** */
/* ************************** */

@media screen and (max-width: 990px) {

    .splash-screen-logo{
        width: 65%;
    }
    
    .splash-play-logo{
        width: 24%;
    }
    /* Add and redeem token */
    .token-heading {
        font-size: 45px;
    }

    .token-count {
        margin: 40px 0;
    }

    .token-counter {
        justify-content: center;
        gap: 15px;
    }

    .token-amount-box-border {
        width: 50%;
    }

    .transaction-btn {
        margin-top: 45px;
    }

    .transaction-box {
        width: 90%;
        height: 55%;
    }
}

/* ************************** */
/* ************************** */
/* *******width 928px******* */
/* ************************** */
/* ************************** */

@media screen and (max-width: 928px) {
    /* Game Screen */

    .game-rule-box,
    .stats-screen-container {
        width: 85%;
        height: 65%;
        overflow: auto;
    }

    .game-terms-box {
        width: 95%;
    }

    .game-hotkey-box {
        width: 95%;
    }

    .stats-screen-container {
        overflow: hidden;
        width: 95%;
    }

    .stats-subheading-bg,
    .leaderboard-subheading-bg,
    .wallet-subheading-bg {
        width: 90%;
    }

    .stats-subheading,
    .leaderboard-subheading,
    .leaderboard-subheading-data,
    .wallet-subheading-data,
    .wallet-subheading {
        font-size: 17px;
        font-weight: 600;
    }

    /* Stats Screen */
    .stats-screen-container {
        height: 75%;
    }

    .stats-box {
        width: 95%;
        margin: 15px 0;
    }

    .stats-heading-box {
        margin-top: 15px;
    }

    .stats-headings {
        width: 65%;
    }

    .stats-chart {
        top: -30px;
        width: 50%;
        height: 50%;
    }

    .stats-data-heading {
        width: 85px;
    }

    .no-stats-found {
        top: 120%;
        width: 100%;
        text-align: center;
    }

    /* Leaderboard and Wallet */
    .leaderboard-container,
    .wallet-container {
        width: 95%;
        height: 80%;
    }

    .leaderboard-users-container,
    .wallet-transaction-history-container {
        width: 95%;
    }

    .leadboard-user-bg-black,
    .wallet-user-bg-black {
        width: 100%;
    }

    .leaderboard-subheading-bg,
    .wallet-subheading-bg {
        width: 95%;
    }

    /* Dashboard screen */
    .dashboard-container {
        width: 95%;
        overflow: hidden;
    }

    /* Hotkeys */
    .hotkey-toggle-box {
        margin: 0;
    }

    .key-cap {
        width: 40px;
        height: 40px;
        font-size: 23px;
    }

    .hotkey-keyboard {
        margin: 0;
    }

    .key-value {
        font-size: 18px;
    }

    .hokey-sub-heading {
        font-size: 25px;
    }
}

/* ************************** */
/* ************************** */
/* *******width 860px******* */
/* ************************** */
/* ************************** */

@media screen and (max-width: 860px) {
    .result-message {
        width: 430px;
    }
}

/* ************************** */
/* ************************** */
/* *******width 735px******* */
/* ************************** */
/* ************************** */

@media screen and (max-width: 735px) {
    /* Hotkey screen */

    .game-hotkey-box {
        height: 500px;
    }

    .key-cap {
        width: 35px;
        height: 35px;
        font-size: 20px;
    }

    .keys-container {
        gap: 20px;
    }

    .key-box {
        gap: 5px;
    }
}

/* ************************** */
/* ************************** */
/* *******width 728px******* */
/* ************************** */
/* ************************** */

@media screen and (max-width: 728px) {
    .game-container {
        gap: 10px;
        width: 100%;
    }

    .game-heading {
        width: 44%;
    }

    .add-box {
        width: 90%;
    }

    .win-lose-balance-box {
        width: 90%;
    }

    .wlb-txt {
        font-size: 16px;
    }

    .coin-flip-box {
        width: 90%;
        height: 250px;
        background-size: cover;
    }

    .streak-multiplier-txt {
        font-weight: 700;
    }

    .result-box-bg {
        width: 90%;
    }

    .bid-box-bg {
        background-size: contain;
        width: 90%;
        height: 140px;
    }

    .min-max-box,
    .half-twoX-box {
        height: 75%;
    }

    .bid-increase-decrease-box {
        width: 50%;
    }

    .footer-bg {
        width: 90%;
    }

    .footer-txt {
        font-size: 15px;
        font-weight: 700;
    }

    .change-game-mode {
        width: 35%;
    }

    /* stats screen */
    .stats-screen-container {
        width: 95%;
    }

    .stats-subheading-bg {
        width: 95%;
    }

    .stats-box {
        width: 95%;
    }

    .stats-heading {
        font-size: 43px;
    }

    .stats-subheading {
        font-size: 15px;
    }

    .stats-box {
        margin: 20px 0;
    }

    /* Dashboard screen */
    .dashboard-stats-container {
        width: 98%;
        justify-content: flex-start;
    }

    .dashboard-heading {
        font-size: 43px;
    }

    .dashboard-stat-value,
    .dashboard-stat-name {
        font-size: 21px;
    }

    .dashboard-right-side {
        position: absolute;
        width: 100%;
        height: 400px;
    }

    .pie-chart {
        width: 60%;
        left: 43%;
    }
}

/* ************************** */
/* ************************** */
/* *******width 700px******* */
/* ************************** */
/* ************************** */

@media screen and (max-width: 700px) {
    .transaction-txt {
        font-size: 18px;
    }

    .icon {
        display: none;
    }

    .ham-burger {
        display: block;
    }

    .game-container-wrapper {
        height: 100%;
    }

    .border-top {
        width: 93.5%;
    }

    .bid-container {
        width: 90%;
    }

    .border-right,
    .border-left {
        right: 3%;
        height: 86%;
    }

    .inside-background {
        bottom: 15px;
    }

    .bid-box-border-left,
    .bid-box-border-right {
        height: 80%;
    }

    .bid-box-border-top {
        width: 95%;
        top: 8%;
    }

    .bid-value {
        font-size: 36px;
    }

    /* leaderboard and wallet */
    .leaderboard-heading,
    .wallet-heading {
        font-size: 40px;
    }

    .leaderboard-subheading,
    .leaderboard-subheading-data,
    .wallet-subheading-data,
    .wallet-subheading {
        font-size: 15px;
    }

    /* Add token and redeem token */
    .addToken-container,
    .redeemToken-container {
        width: 70%;
    }

    .token-heading {
        font-size: 40px;
    }

    .token-count {
        margin: 30px 0;
        font-size: 24px;
    }

    .token-counter {
        justify-content: space-between;
    }

    .token-counter {
        gap: 15px;
    }

    .token-amount-box-border {
        width: 60%;
    }

    .transaction-btn {
        margin-top: 45px;
    }

    /* Hotkey screen */
    .game-hotkey-box {
        justify-content: flex-start;
    }

    .hotkey-keyboard {
        height: initial;
    }

    .keys-container {
        margin: 25px 0;
        gap: 20px 8px;
        width: 100%;
    }

    /* splash screen */
    .splash-screen-logo {
        width: 80%;
        object-fit: contain;
    }

    .splash-play-logo {
        width: 35%;
    }

    /* Netowork Error */

    .internet-connectivity {
        width: 88%;
        height: 50%;
    }
}

/* ************************** */
/* ************************** */
/* *******width 628px******* */
/* ************************** */
/* ************************** */
@media screen and (max-width: 628px) {
    .border-right {
        right: 18px;
    }

    .border-top {
        width: 93%;
    }

    .result-message {
        width: 360px;
        height: 110px;
    }

    /* Add token and redeem token */

    .addToken-container,
    .redeemToken-container {
        padding: 15px;
    }

    .token-counter {
        width: 95%;
    }

    .redeem-heading {
        font-size: 32px;
    }

    /* Stats screen */
    .stats-chart {
        width: 60%;
        height: 60%;
    }

    .no-stats-found {
        top: 100%;
    }

    /* Dashboard screen */
    .dashboard-stat-color-box {
        width: 40px;
        height: 30px;
    }

    .dashboard-stat-value,
    .dashboard-stat-name {
        font-size: 19px;
    }

    .pie-chart {
        width: 70%;
        left: 33%;
    }

    /* Wallet Transaction Screen */
    .wallet-subheading-bg {
        width: 100%;
    }

    .wallet-transaction-history-container {
        width: 100%;
    }
}

/* ************************** */
/* ************************** */
/* *******width 600px******* */
/* ************************** */
/* ************************** */
@media screen and (max-width: 600px) {
    .ham-burger {
        width: 43px;
        height: 43px;
    }

    .theme-button {
        width: 55px;
        height: 18px;
    }

    .coin {
        width: 155px;
        height: 155px;
    }

    .position-set {
        width: 40px;
        height: 40px;
    }

    .coin-icon {
        width: 40px;
        height: 40px;
    }

    /* light bet btns */
    .light-bet-btn {
        font-size: 18px;
    }

    /* Hotkey */
    .keys-container {
        width: 90%;
        justify-content: space-between;
    }
}

/* ************************** */
/* ************************** */
/* *******width 590px******* */
/* ************************** */
/* ************************** */

@media screen and (max-width: 590px) {
    .streak-multiplier-box {
        width: 90%;
    }

    .bet-btns-box {
        width: 80%;
    }

    .bet-btn {
        width: 170px;
    }
}

/* ************************** */
/* ************************** */
/* *******width 550px******* */
/* ************************** */
/* ************************** */

@media screen and (max-width: 550px) {

    .description-heading,
    .rule-heading,
    .terms-heading,
    .hotkey-heading {
        font-size: 30px;
    }

    .wlb-txt {
        font-size: 14px;
    }

    .coin-flip-box {
        height: 210px;
    }

    .streak-multiplier-txt {
        font-size: 13px;
        font-weight: 600;
    }

    .bid-box-bg {
        height: 140px;
    }

    .bid-container {
        margin-right: 3px;
    }

    .bid-value {
        font-size: 32px;
    }

    .footer-txt {
        font-size: 14px;
    }

    /* Setting Slider */

    .setting-container {
        padding: 60px 20px;
    }

    .sound-container {
        margin-top: 30px;
    }

    .sound-level-bar {
        width: 95%;
    }

    .action-box-img {
        width: 43px;
        height: 43px;
    }

    .action-lable-name {
        font-size: 16px;
    }

    /* Add token and redeem token */
    .addToken-container,
    .redeemToken-container {
        width: 95%;
    }

    .token-counter {
        justify-content: center;
        gap: 8px;
    }

    .token-amount-box-border {
        width: 53%;
        height: 50px;
    }

    .token-amount-bg {
        inset: 3px;
    }

    .transaction-btn {
        margin-top: 35px;
    }

    /* Stats screen */

    .stats-subheading-bg::before {
        inset: 3px;
    }

    .waged-heading {
        font-size: 17px;
    }

    .heading-txt {
        font-size: 13px;
    }

    .heading-color {
        width: 10px;
        height: 10px;
    }

    .stats-data-heading {
        width: 65px;
    }

    .stats-chart {
        width: 70%;
        height: 70%;
    }

    .no-stats-found {
        top: 85%;
    }

    /* Dashboard screen */
    .dashboard-stat-color-box {
        width: 30px;
    }

    .pie-chart {
        left: 36%;
    }

    .dashboard-left-side {
        position: relative;
        z-index: 10;
    }

    .dashboard-stat-name {
        font-size: 16px;
    }

    .account-heading {
        font-size: 25px;
    }

    .wallet-address-id,
    .wallet-balance-txt {
        font-size: 15px;
    }

    .wallet-balance-value {
        font-size: 28px;
    }
}

/* ************************** */
/* ************************** */
/* *******width 500px******* */
/* ************************** */
/* ************************** */

@media screen and (max-width: 500px) {
    .win-lose-balance-box {
        height: 40px;
    }

    .wlb-txt {
        font-size: 13px;
        font-weight: 600;
    }

    .streak-multiplier-box {
        height: 45px;
    }

    .coin {
        height: 135px;
        width: 135px;
    }

    .bid-increase-decrease-box {
        width: 70%;
    }

    .bid-btn {
        width: 65px;
    }

    .footer-bg {
        /* margin-top: 10px; */
        height: 45px;
    }

    .footer-txt {
        font-size: 13px;
        font-weight: 600;
    }

    /* Rule Screen */
    .description-heading,
    .rule-heading,
    .terms-heading,
    .hotkey-heading {
        font-size: 26px;
    }

    .description-txt,
    .rule-txt {
        font-size: 15px;
    }
}

/* ************************** */
/* ************************** */
/* *******width 478px******* */
/* ************************** */
/* ************************** */
@media screen and (max-width: 478px) {
    .add-box {
        width: 95%;
    }

    .game-container {
        gap: 7px;
    }

    .setting-container {
        height: 103%;
    }

    .game-heading {
        height: initial;
        width: 55%;
    }

    .wlb-txt {
        text-align: center;
    }

    .win-lose-balance-box {
        width: 95%;
    }

    .win-lose-balance-box {
        height: initial;
        padding: 10px 0;
    }

    .coin-flip-box {
        width: 95%;
        height: 170px;
    }

    .inside-1-coin-flip-box {
        left: 3px;
        right: 3px;
        top: 4px;
    }

    .border-left {
        left: 10px;
        height: 89%;
    }

    .border-right {
        right: 10px;
        height: 89%;
    }

    .border-top {
        left: initial;
    }

    .inside-background {
        top: 10px;
        right: 12px;
        left: 12px;
        bottom: 8px;
    }

    .streak-multiplier-box {
        width: 93%;
    }

    .streak-box,
    .multiplier-box {
        margin-left: 10px;
    }

    .streak-multiplier-txt {
        font-size: 12px;
    }

    .result-box-bg {
        width: 95%;
    }

    .bid-box-bg {
        height: 120px !important;
    }

    .bid-box-inside-1 {
        left: 0;
        right: 0;
        top: 3px;
    }

    .bid-box-border-left {
        left: 7px;
        height: 83%;
    }

    .bid-box-border-right {
        right: 10px;
        height: 83%;
    }

    .border-top {
        width: 95%;
        top: 8px;
    }

    .bid-box-inside-color {
        top: 10px;
        right: 12px;
        left: 9px;
        bottom: 8px;
    }

    .bid-container {
        width: 92%;
        height: 100%;
    }

    .bid-increase-decrease-box {
        width: 60%;
    }

    .bet-btn {
        width: 140px;
        height: 50px;
    }

    .footer-bg {
        height: initial;
        padding: 10px 0;
        width: 95%;
    }

    .footer-txt-box {
        width: 95%;
    }

    .footer-btn {
        width: 50px;
        height: 18px;
    }

    .footer-circle {
        width: 25px;
        height: 25px;
        top: -4.4px;
    }

    /* light bet btns */
    .light-bet-btn {
        font-size: 15px;
    }

    .bet-coin-image {
        width: 30px;
    }

    /* stats screen */

    .stats-subheading-bg {
        height: initial;
        padding: 5px 0;
    }

    .stats-vertical-line {
        width: 2.8px;
    }

    .stats-heading-box {
        width: 90%;
        align-items: flex-start;
    }

    .stats-chart {
        width: 90%;
        height: 90%;
        left: -5%;
    }

    .stats-headings {
        flex-direction: column;
        width: initial;
        align-items: flex-start;
        gap: 20px;
    }

    .win-data-heading,
    .stats-data-heading {
        justify-content: flex-start;
        gap: 8px;
        width: initial;
    }

    .stats-box {
        margin: 0;
    }

    .stats-screen-container {
        height: 65%;
    }

    .no-stats-found {
        top: 70%;
        left: 60%;
    }

    /* Dashboard screen */
    .dashboard-container {
        padding-left: 5px;
        padding-right: 5px;
    }

    .dashboard-heading {
        font-size: 34px;
    }

    .dashboard-stat-color-box {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 0.5px solid #fff;
    }

    .dashboard-stat-value {
        margin-left: 8px;
    }

    .pie-chart {
        left: 38%;
    }

    .dashboard-stat-value,
    .dashboard-stat-name {
        font-size: 15px;
    }

    /* wallet transaction screen */
    .hash-id {
        width: 120px;
    }

    .wallet-middle-wrapper {
        width: 20%;
    }

    .leaderboard-subheading,
    .leaderboard-subheading-data,
    .wallet-subheading-data,
    .wallet-subheading {
        font-size: 14px;
    }

    .wallet-subheading-bg {
        height: initial;
        padding: 5px 0;
    }

    .wallet-subheading-bg::before {
        inset: 3px;
    }

    .wallet-user-bg-black {
        height: initial;
        padding: 5px 0;
    }

    .wallet-user-bg-black::before {
        inset: 3px;
    }

    .wallet-transaction-history-container {
        padding-top: 0;
    }

    .wallet-heading {
        font-size: 32px;
    }

    .account-heading {
        font-size: 22px;
    }

    .wallet-balance-value {
        font-size: 25px;
    }

    .wallet-details {
        width: 100%;
    }
}

/* ************************** */
/* ************************** */
/* *******width 430px******* */
/* ************************** */
/* ************************** */

@media screen and (max-width: 430px) {
    .right-header {
        gap: 5px;
    }

    .add-token-btn {
        width: 6rem;
    }

    .add-token-icon-text {
        font-size: 1.25rem;
        line-height: 1rem;
    }

    .game-bg {
        padding: 0;
        /* height: 92vh; */
    }

    .game-header {
        height: initial;
        margin-top: 10px;
    }

    .add-box {
        height: 150px;
        width: 98%;
        padding: .2rem;
        border-radius: 1rem;
        margin-top: 1rem;
    }

    .win-lose-balance-box {
        justify-content: space-between;
        padding: 10px;
        width: 98%;
    }

    .result-message {
        width: 312px;
        height: 92px;
    }

    .game-container-wrapper {
        min-height: 90vh !important;
    }

    .setting-container {
        padding-top: 50px;
    }

    .setting-game-heading {
        margin-top: 40px;
    }

    .sound-container {
        margin-top: 25px;
        gap: 25px;
    }

    .setting-action-container {
        gap: 10px;
    }

    .copyright-subheading {
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .footer-txt {
        font-size: 10px;
    }

    .footer-btn {
        width: 40px;
        height: 15px;
    }

    .change-game-mode {
        width: 30%;
    }

    .footer-circle {
        width: 20px;
        height: 20px;
        top: -3px;
    }

    .right-arrow {
        height: 9px;
    }

    .footer-bg::before,
    .win-lose-balance-box::before {
        inset: 2px;
    }

    .bid-value {
        font-size: 27px;
    }

    .bid-btn {
        width: 60px;
    }

    .position-set {
        width: 35px;
    }

    .bid-increase-decrease-box {
        width: 51%;
    }

    /* setting slider */
    .setting-container {
        padding-right: 15px;
    }

    .setting-action-container {
        margin-top: 30px;
    }

    .setting-game-heading {
        width: 220px;
    }

    /* leaderboard and wallet */
    .leaderboard-heading {
        font-size: 28px;
    }

    .leaderboard-container,
    .wallet-container {
        padding-bottom: 30px;
        height: 65%;
    }

    .leaderboard-subheading,
    .leaderboard-subheading-data,
    .wallet-subheading-data,
    .wallet-subheading {
        font-size: 12px;
    }

    .stats-vertical-line,
    .leaderboard-vertical-line {
        height: 24.5px;
    }

    /* Hotkey screens */
    .game-hotkey-box {
        height: initial;
    }

    .keys-container {
        margin: 10px 0;
    }

    /* Network Error */
    .internet-connectivity {
        height: initial;
    }

    .net-icon {
        height: 150px;
    }

    .network-heading {
        font-size: 38px;
    }

    .internet-status-message {
        font-size: 17px;
        margin-bottom: 15px;
    }
}

/* ************************** */
/* ************************** */
/* *******width 375px******* */
/* ************************** */
/* ************************** */

@media screen and (max-width: 375px) {
    .game-header-375 {
        flex-direction: column-reverse;
        align-items: flex-end;
        gap: 0.5rem;
        height: 4.5rem;
    }

    .game-container {
        margin-top: 0;
    }

    .wallet-add,
    .user-coin-value {
        font-size: 1rem;
    }

    .game-container-wrapper {
        height: 95%;
    }

    .ham-burger {
        width: 38px;
        height: 38px;
    }

    .result-message {
        width: 258px;
        height: 105px;
    }

    .game-heading {
        width: 50%;
    }

    .wlb-txt {
        font-size: 11px;
    }

    .streak-multiplier-box {
        padding: 3px 0;
        height: initial;
    }

    .streak-multiplier-txt {
        font-size: 11px;
    }

    .multiplier-box {
        margin-right: 8px;
        margin-left: 0;
    }

    .streak-box {
        margin-left: 6px;
    }

    .coin-flip-box {
        height: 150px;
    }

    .coin {
        height: 115px;
        width: 115px;
    }

    .result-cover-box {
        inset: 4px;
    }

    .bet-btns-box {
        width: 92%;
    }

    .footer-bg {
        width: 98%;
        /* margin-top: 5px; */
    }

    .footer-txt-box {
        width: 97%;
    }

    /* Add token and redeem token */
    .transaction-txt {
        line-height: 20px;
        font-size: 16px;
    }

    .token-heading {
        font-size: 24px;
    }

    .token-count {
        font-size: 18px;
        margin: 20px 0;
    }

    .token-btn {
        width: 180px;
    }

    /* LeaderBoard */
    .leaderboard-container,
    .wallet-container {
        padding-bottom: 20px;
    }

    /* Wallet Transaction screen */
    .account-heading {
        font-size: 18px;
    }

    .wallet-address-id,
    .wallet-balance-txt {
        font-size: 13px;
    }

    .wallet-balance-value {
        font-size: 20px;
    }

    .wallet-container {
        padding: 10px 5px;
    }
}

/* ************************** */
/* ************************** */
/* *******width 328px******* */
/* ************************** */
/* ************************** */

@media screen and (max-width: 328px) {
    .footer-txt {
        font-size: 10px;
    }
}